<template>
	<app-hero v-once />
	<main role="main" class="main">
		<router-view />
		<app-holidays />
	</main>
	<app-footer v-once />
	<app-cookie-consent />
	<app-service-worker-toast />
	<app-floating-buttons />
</template>

<script>
	"use strict";

	// 

	import { defineComponent } from "vue";

	import Hero from "@/components/Hero";
	// import CovidAutomat from "@/components/CovidAutomat";
	// import CovidMessage from "@/components/CovidMessage";
	// import Support from "@/components/Support";
	// import Holidays from "@/components/HolidaysWithoutAds";
	import Holidays from "@/components/HolidaysWithAds";
	import Footer from "@/components/Footer";
	import CookieConsent from "@/components/CookieConsent";
	import ServiceWorkerToast from "@/components/ServiceWorkerToast";
	import FloatingButtons from "@/components/FloatingButtons";

	export default {
		name: "App",
		components: {
			"app-hero": defineComponent(Hero),
			// "app-covid-automat": defineComponent(CovidAutomat),
			// "app-covid-message": defineComponent(CovidMessage),
			// "app-support": defineComponent(Support),
			"app-holidays": defineComponent(Holidays),
			"app-footer": defineComponent(Footer),
			"app-cookie-consent": defineComponent(CookieConsent),
			"app-service-worker-toast": defineComponent(ServiceWorkerToast),
			"app-floating-buttons": defineComponent(FloatingButtons)
		},
		setup() {
			const { matches: isPrint } = matchMedia("print");
			if(isPrint) ga("send", "event", { // eslint-disable-line no-undef
				eventCategory: "Web Analytics",
				eventAction: "print-page",
				nonInteraction: true
			});

			const { matches: isPwa } = matchMedia("(display-mode: standalone)");
			if (isPwa || navigator.standalone) ga("send", "event", { // eslint-disable-line no-undef
				eventCategory: "Web Analytics",
				eventAction: "page-pwa",
				nonInteraction: true
			});
		}
	};
</script>

<style lang="scss">
	@charset "utf-8";

	//TODO: presunut do styles/base.scss

	@import "@/styles/mixins";
	@import "@/styles/variables";

	html, html > body, #app {
		@include rectangle(100%, 100%);
		min-height: 100%;
	}

	html > body, #app {
		@include flex-col;
	}

	#app .main {
		flex: 1;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition-property: opacity;
		transition-duration: $transition-duration;
		transition-timing-function: $transition-timing-function;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	[v-cloak] {
		display: none !important;
	}
</style>
