"use strict";

export const isArray = (array = []) => !!array && Array.isArray(array);

export const chunk = (array = [], size = 2) => {
	if(!Array.isArray(array)) return;
	array = [ ...array ];
	size = size | 0;
	const chunks = [];
	for(let i = 0; i < array.length; i += size) chunks.push(array.slice(i, i + size));
	return chunks;
};

export const random = (array = []) => array[Math.floor((Math.random() * array.length))];
