"use strict";

const now = () => (new Date).getTime();

const getRoot = () =>  document.documentElement || document.body.parentNode || document.body;

export const scrollToTop = () => getRoot().scrollIntoView({ behavior: "smooth" });

export const scrollTop = () => window.pageYOffset || getRoot().scrollTop;

export const throttle = (callback = () => {}, interval = 250) => {
	let lastInterval, lastTimestamp = 0;
	const delta = () => now() - lastTimestamp;
	return function() {
		const fn = () => {
			callback.apply(null, arguments);
			lastTimestamp = now();
		};
		if(!lastTimestamp) fn.call();
		else {
			if(lastInterval) clearTimeout(lastInterval);
			lastInterval = setTimeout(() => {
				if(delta() >= interval) fn.call();
			}, interval - delta());
		}
	};
};
