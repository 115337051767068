<template>
	<section v-if="calendar" :key="$route.params.month" class="section calendar-section">
		<div class="container">
			<div class="columns is-desktop">
				<div class="column">
					<h3 class="title is-marginless">
						{{ calendar.title }} {{ calendar.year }}
					</h3>
					<h4 class="subtitle is-hidden-screen">
						<a rel="noopener" target="_blank" :href="`https://${domain}/podporit`" title="Podporiť tento projekt">
							{{ `${domain}/podporit` }}
						</a>
					</h4>
				</div>
				<div class="column is-narrow is-hidden-touch">
					<form class="form" @submit.prevent>
						<div class="field">
							<input type="checkbox" id="show-school-holidays" class="switch is-rounded" v-model="filters.school" />
							<label for="show-school-holidays" title="Zvýrazniť školské prázdniny">Zvýrazniť školské prázdniny</label>
						</div>
						<div class="field">
							<input type="checkbox" id="show-public-holidays" class="switch is-rounded" v-model="filters.public" />
							<label for="show-public-holidays" title="Zvýrazniť štátne sviatky a dni pracovného pokoja">Zvýrazniť štátne sviatky a dni pracovného pokoja</label>
						</div>
					</form>
				</div>
			</div>
			<div class="calendar" :class="{ 'hide-public-holidays': !filters.public, 'hide-school-holidays': !filters.school }">
				<div class="row-desktop has-text-grey is-hidden-touch">
					<div v-for="({ title }, colIndex) of weekdays" :key="colIndex" class="col-desktop has-text-centered">
						{{ title }}
					</div>
				</div>
				<div v-for="(row, rowIndex) of calendar.data" :key="rowIndex" class="col-touch row-desktop">
					<div v-for="(col, colIndex) of row" :key="colIndex" class="row-touch col-desktop" :class="{ [col.classes.join(' ')]: col.classes.length, 'is-hidden-touch': col.classes.length && col.classes.includes('is-disabled'), 'is-relative': !colIndex }">
						<div v-if="col.classes.length && col.classes.includes('is-today')" class="tag is-dark is-rounded is-uppercase has-text-centered is-hidden-touch">
							Dnes
						</div>
						<div v-if="!colIndex && !!col.week" class="week is-hidden-touch">
							{{ col.week }}.
						</div>
						<div class="weekday has-text-centered has-text-left-touch is-hidden-desktop">
							{{ shorten(col.title) }}.
						</div>
						<div v-if="col.tooltip" class="day has-text-centered has-text-right-touch has-text-weight-bold has-tooltip-arrow has-tooltip-right-touch has-tooltip-bottom-desktop has-tooltip-fade" :class="`has-tooltip-text-${col.classes.length && col.classes.includes('is-school-holiday-regional') ? 'left' : 'centered'}`" :data-tooltip="col.tooltip"><!-- tooltip musi byt pridany aj vo whiteliste pre purgecss -->
							{{ col.day }}
						</div>
						<div v-else class="day has-text-centered has-text-right-touch has-text-weight-bold">
							{{ col.day }}
						</div>
						<div v-if="col.classes.length && col.classes.includes('is-today')" class="tag is-dark is-rounded is-uppercase has-text-centered is-hidden-desktop">
							Dnes
						</div>
						<div v-if="col.nameday" class="nameday has-text-centered has-text-right-touch">
							{{ col.nameday }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	"use strict";

	import { ref, watch } from "vue";
	import { useRouter, useRoute } from "vue-router";
	import { DOMAIN, SCHOOL_YEAR_ARRAY, CURRENT_YEAR, CURRENT_MONTH, CURRENT_DAY, MONTHS, WEEKDAYS, NAMEDAYS, PUBLIC_HOLIDAYS, SCHOOL_HOLIDAYS } from "@/data";
	import { setTitle, setBreadcrumbsStructuredData } from "@/router";
	import { storage } from "@/storage";
	import { chunk } from "@/utils/Array";
	import { getWeekNumber, getFirstDayOfMonthDate, getLastDayOfMonthDate, getMonthDayCount, getLastMonthDayCount } from "@/utils/Date";
	import { pad, shorten } from "@/utils/String";

	const ArrayUtils = { chunk };
	const DateUtils = { getWeekNumber, getFirstDayOfMonthDate, getLastDayOfMonthDate, getMonthDayCount, getLastMonthDayCount };
	const StringUtils = { pad, shorten };

	const STORE_COOKIE_CONSENT_KEY = "cookie-consent:is-hidden";
	const STORE_FILTERS_KEY = "calendar:filters:form";
	const NOT_CURRENT_MONTH_CLASSES = [ "is-disabled" ];

	const DEFAULT_FILTERS = {
		school: true,
		public: true
	};

	export default {
		name: "Calendar",
		setup() {
			const router = useRouter();
			const route = useRoute();
			const calendar = ref();
			const filters = ref(Object.assign({}, DEFAULT_FILTERS, JSON.parse(storage.get(STORE_FILTERS_KEY))));

			const _generateDay = (year = 0, month = 0, day = 0, options = {}) => {
				const DAY_DATE = new Date((year | 0), (month | 0) - 1, (day | 0));

				const WEEKDAY = DAY_DATE.getDay() || 7;
				const { title: WEEKDAY_TITLE } = WEEKDAYS[WEEKDAY - 1];

				const DAY_OBJECT = {
					year: DAY_DATE.getFullYear(),
					month: DAY_DATE.getMonth() + 1,
					// week: DateUtils.getWeekNumber(DAY_DATE),
					day: DAY_DATE.getDate(),
					weekday: WEEKDAY,
					title: WEEKDAY_TITLE,
					nameday: "",
					classes: [],
					tooltip: []
				};

				if(WEEKDAY === 1) Object.assign(DAY_OBJECT, { week: DateUtils.getWeekNumber(DAY_DATE) });

				const DAY_STRING = [ DAY_OBJECT.year, DAY_OBJECT.month, DAY_OBJECT.day ].map((n, i) => StringUtils.pad(n, !i ? 4 : 2, 0)).join("-");

				if(DAY_OBJECT.year === CURRENT_YEAR && DAY_OBJECT.month === CURRENT_MONTH && DAY_OBJECT.day === CURRENT_DAY) DAY_OBJECT.classes.push("is-today");

				if(DAY_OBJECT.weekday > 5) DAY_OBJECT.classes.push("is-weekend");

				PUBLIC_HOLIDAYS.filter(({ month, day, isNotHoliday }) => !isNotHoliday && month === DAY_OBJECT.month && day === DAY_OBJECT.day).forEach(({ title }) => {
				// PUBLIC_HOLIDAYS.filter(({ date }) => date === DAY_STRING).forEach(({ title }) => {
					DAY_OBJECT.classes.push("is-public-holiday");
					/* if(filters.value.public) */ DAY_OBJECT.tooltip.push(title);
				});

				SCHOOL_HOLIDAYS.filter(({ startDate, endDate }) => startDate <= DAY_STRING && endDate >= DAY_STRING).forEach(({ title, status /* , startDate, endDate */ }) => {
					DAY_OBJECT.classes.push("is-school-holiday");
					if(status === 2) DAY_OBJECT.classes.push("is-school-holiday-regional");
					// if(DAY_STRING === startDate) DAY_OBJECT.classes.push("school-holidays-start");
					// if(DAY_STRING === endDate) DAY_OBJECT.classes.push("school-holidays-end");
					/* if(filters.value.school) */ DAY_OBJECT.tooltip.push(title);
				});

				const { title: NAMES } = NAMEDAYS.find(({ month, day }) => month === DAY_OBJECT.month && day === DAY_OBJECT.day);
				// const { title: NAMES } = NAMEDAYS.find(({ date }) => date === DAY_STRING);

				Object.assign(DAY_OBJECT, {
					nameday: NAMES ? NAMES /* ak je to array: .filter(String) */ : DAY_OBJECT.nameday,
					tooltip: DAY_OBJECT.tooltip.join("\n"),
					classes: DAY_OBJECT.classes.concat(options?.classes)
				});

				return DAY_OBJECT;
			};

			const _generateMonth = (year = 0, month = 0) => {
				const MONTH_DATE = DateUtils.getFirstDayOfMonthDate(year, month);

				const MONTH_OBJECT = {
					year: MONTH_DATE.getFullYear(),
					month: MONTH_DATE.getMonth() + 1,
					title: "",
					slug: "",
					data: []
				};

				const MONTH_FIRST_DAY_DATE = DateUtils.getFirstDayOfMonthDate(MONTH_OBJECT.year, MONTH_OBJECT.month);
				const MONTH_LAST_DAY_DATE = DateUtils.getLastDayOfMonthDate(MONTH_OBJECT.year, MONTH_OBJECT.month);

				const MONTH_DAY_COUNT = DateUtils.getMonthDayCount(MONTH_OBJECT.year, MONTH_OBJECT.month);
				const PREVIOUS_MONTH_DAY_COUNT = DateUtils.getLastMonthDayCount(MONTH_OBJECT.year, MONTH_OBJECT.month);

				const EMPTY_CELLS_START = (MONTH_FIRST_DAY_DATE.getDay() || 7) - 1;
				const EMPTY_CELLS_END = 7 - (MONTH_LAST_DAY_DATE.getDay() || 7);

				const { title: MONTH_TITLE, slug: MONTH_SLUG } = MONTHS.find(({ index }) => index === MONTH_OBJECT.month);
				Object.assign(MONTH_OBJECT, { title: MONTH_TITLE, slug: MONTH_SLUG });

				for(let day = PREVIOUS_MONTH_DAY_COUNT - (EMPTY_CELLS_START - 1); day <= PREVIOUS_MONTH_DAY_COUNT; day++) {
					const DAY_OBJECT = _generateDay(MONTH_OBJECT.year, MONTH_OBJECT.month - 1, day, { classes: NOT_CURRENT_MONTH_CLASSES });
					MONTH_OBJECT.data.push(DAY_OBJECT);
				}

				for(let day = 1; day <= MONTH_DAY_COUNT; day++) {
					const DAY_OBJECT = _generateDay(MONTH_OBJECT.year, MONTH_OBJECT.month, day);
					if(day === 1) DAY_OBJECT.classes.push("is-first-day");
					else if(day === MONTH_DAY_COUNT) DAY_OBJECT.classes.push("is-last-day");
					MONTH_OBJECT.data.push(DAY_OBJECT);
				}

				for(let day = 1; day <= EMPTY_CELLS_END; day++) {
					const DAY_OBJECT = _generateDay(MONTH_OBJECT.year, MONTH_OBJECT.month + 1, day, { classes: NOT_CURRENT_MONTH_CLASSES });
					MONTH_OBJECT.data.push(DAY_OBJECT);
				}

				Object.assign(MONTH_OBJECT, { data: ArrayUtils.chunk(MONTH_OBJECT.data, 7) });

				return MONTH_OBJECT;
			};

			const onRouteChange = () => {
				const { params: { month: SLUG } } = route;
				if(!SLUG || !MONTHS.map(({ slug }) => slug).includes(SLUG)) return router.push("/");

				const { index: MONTH, title: TITLE } = MONTHS.find(({ slug }) => slug === SLUG);
				const YEAR = SCHOOL_YEAR_ARRAY[MONTH >= 9 ? 0 : 1];

				setTitle(`${TITLE} ${YEAR}`);
				setBreadcrumbsStructuredData();

				calendar.value = _generateMonth(YEAR, MONTH);
			};

			onRouteChange();

			watch(() => route.params.month, () => onRouteChange());

			// watchEffect(() => onRouteChange());

			watch(filters, () => storage.get(STORE_COOKIE_CONSENT_KEY) && storage.set(STORE_FILTERS_KEY, JSON.stringify(filters.value)), { deep: true });

			return {
				domain: DOMAIN,
				filters,
				calendar,
				weekdays: WEEKDAYS,
				shorten: StringUtils.shorten
			};
		}
	};
</script>

<style scoped lang="scss">
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	.day {
		transition-property: color;
		transition-duration: $transition-duration;
		transition-timing-function: $transition-timing-function;
	}

	@media /* print, */ screen and (max-width: $desktop - 1px) {
		.calendar {
			@include flex-col;

			.col-touch {
				@include flex-col;
/*
				&:not(:last-of-type) .row-touch,
				&:last-of-type .row-touch:not(:last-of-type) {
					border-bottom: 1px solid $grey-light;
				}
*/
				.row-touch {
					@include flex-row;
					padding: .5rem 1rem;

					&:not(.is-last-day) {
						border-bottom: 1px solid $grey-light;
					}

					&.is-weekend {
						background-color: $grey-lightest;
					}

					&.is-school-holiday .day {
						color: $school-holiday-color;
					}

					&.is-school-holiday-regional .day {
						color: $school-holiday-regional-color;
					}

					&.is-public-holiday .day {
						color: $public-holiday-color;
					}

					&.is-today .tag {
						margin-left: .5rem;
					}	

					.weekday,
					.tag,
					.day,
					.nameday {
						@include flex-col;
					}

					.weekday,
					.day {
						min-width: 2rem;
					}

					.day {
						padding-right: .5rem; // for tooltip
					}

					.nameday {
						flex: 1;
					}
				}
			}
		}
	}

	@media print, screen and (min-width: $desktop) {
		$cell-size: 8.75rem;

		.columns {
			margin-bottom: 1rem;

			& > .column {
				&:not(.is-narrow) {
					@include flex-col-center;
				}
			}
		}

		.calendar {
			@include flex-col;
			max-width: $cell-size * 7;

			&:not(.hide-school-holidays) .row-desktop .col-desktop {
				&.is-school-holiday .day {
					color: $school-holiday-color;
				}

				&.is-school-holiday-regional .day {
					color: $school-holiday-regional-color;
				}
			}

			&:not(.hide-public-holidays) .row-desktop .col-desktop {
				&.is-public-holiday .day {
					color: $public-holiday-color;
				}
			}

			.row-desktop {
				@include flex-row;

				&:first-of-type .col-desktop {
					height: auto;
					padding: .25rem;
				}

				&:not(:last-of-type) {
					border-bottom: 1px solid $grey-light;
				}

				.col-desktop {
					@include flex-col;
					@include square($cell-size);
					padding: 1.125rem;

					&.is-disabled {
						opacity: .375;
					}
/*
					&:hover {
						box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .25);
					}
*/
					&.is-weekend {
						background-color: $grey-lightest;
/*
						&:not(:last-of-type) {
							border-right: 1px solid $white-ter;
						}
*/
					}

					&:not(.is-today) .day {
						padding-top: 1.5rem;
					}

					&.is-today .tag {
						max-width: 3rem;
						margin: 0 auto;
					}

					.week,
					.tag,
					.day,
					.nameday {
						@include flex-col;
					}

					.week {
						position: absolute; top: 0; left: 0;
						padding: .25rem;
						font-size: .875rem;
					}

					.day {
						font-size: 2.25rem;
					}

					.nameday {
						font-size: .75rem;
					}
				}
			}
		}
	}
</style>
