<template>
	<h4 class="title is-size-4 has-text-centered-print">
		{{ title }}
	</h4>
	<h5 class="subtitle is-hidden-screen has-text-centered-print">
		<a rel="noopener" target="_blank" :href="`https://${domain}/podporit`" title="Podporiť tento projekt">
			{{ `${domain}/podporit` }}
		</a>
	</h5>
	<table class="table is-fullwidth" :class="{ 'break-after': breakAfter }">
		<tbody>
			<tr v-for="({ title, isNotHoliday, date, startDate, endDate, inDays }, index) of holidays" :key="index" :class="{ [className]: !!className, 'is-other': (!className || isNotHoliday)}">
				<td v-html="title"></td>
				<td v-if="!showDays" class="has-text-right">
					<template v-if="!!date">
						{{ format(date) }}
					</template>
					<template v-else-if="!!startDate && !!endDate">
						<template v-if="startDate === endDate">
							{{ format(startDate) }}
						</template>
						<template v-else>
							{{ format(startDate) }} - {{ format(endDate) }}
						</template>
					</template>
				</td>
				<td v-else class="has-text-right">
					<template v-if="inDays > 0">
						o {{ inDays }} {{ getDayCountWord(inDays) }}
					</template>
					<template v-else>
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewbox="0 0 24 24" v-bind:svg-inline="'svg-inline'" v-bind:svg-sprite="'svg-sprite'" v-bind:class="'svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><use xlink:href="#svg-sprite-74f6e2f9fa71a2b2acb9488c1bdb5a93" href="#svg-sprite-74f6e2f9fa71a2b2acb9488c1bdb5a93"></use></svg>
					</template>
				</td>
			</tr>
		</tbody>
	<svg xmlns="http://www.w3.org/2000/svg" style="display: none !important;"><symbol id="svg-sprite-74f6e2f9fa71a2b2acb9488c1bdb5a93" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1 1 0 111.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33z"/></symbol></svg></table>
</template>

<script>
	"use strict";

	import { DOMAIN } from "@/data";
	import { formatString, getDayCountWord } from "@/utils/Date";

	const DateUtils = { formatString, getDayCountWord };

	export default {
		name: "HolidaysTable",
		props: {
			title: {
				type: String,
				required: true
			},
			holidays: {
				type: Array,
				required: true
			},
			className: {
				type: String,
				required: false
			},
			showDays: {
				type: Boolean,
				required: false
			},
			breakAfter: {
				type: Boolean,
				required: false
			},
		},
		setup() {
			return {
				domain: DOMAIN,
				format: DateUtils.formatString,
				getDayCountWord: DateUtils.getDayCountWord
			};
		}
	};
</script>

<style scoped lang="scss">
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	.title,
	.subtitle {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	.table {
		.has-text-right {
			white-space: nowrap;
		}

		.svg-icon {
			@include square(1em);
		}

		.is-public-holiday {
			border-left: .25rem solid $public-holiday-color;
		}

		.is-school-holiday {
			border-left: .25rem solid $school-holiday-color;
		}

		.is-school-holiday-regional {
			border-left: .25rem solid $school-holiday-regional-color;
		}

		.is-other {
			border-left: .25rem solid $grey-lighter; // $grey-lightest;
		}
	}
</style>
