"use strict";

import { defineComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import Calendar from "@/views/Calendar";
import { DOMAIN, SCHOOL_YEAR_STRING, MONTHS, CURRENT_MONTH } from "@/data";

const CURRENT_MONTH_FIXED = [ 7, 8 ].includes(CURRENT_MONTH) ? 9 : CURRENT_MONTH;
const { slug: CURRENT_MONTH_SLUG } = MONTHS.find(({ index }) => index === CURRENT_MONTH_FIXED);

export const DEFAULT_ROUTE = { name: "Calendar", params: { month: CURRENT_MONTH_SLUG }};

/* export */ const DEFAULT_TITLE = `Školský kalendár ${SCHOOL_YEAR_STRING.replace(/\s+/g, " ")}`;

export const setTitle = (title = "") => title && (document.title = (document.head || document).querySelector("meta[property=\"og:title\"]").content = `${title} | ${DEFAULT_TITLE}`);

export const setBreadcrumbsStructuredData = () => {
	const { head, title: TITLE, URL } = document;

	const script = document.createElement("script");
	script.id = "breadcrumbs-structured-data";
	script.type = "application/ld+json";

	script.textContent = JSON.stringify({
		"@context": "https://schema.org/",
		"@type": "BreadcrumbList",
		"itemListElement": [ {
			"@type": "ListItem",
			"position": 1,
			"name": DEFAULT_TITLE,
			"item": `https://www.${DOMAIN}/`
		}, {
			"@type": "ListItem",
			"position": 2,
			"name": TITLE,
			"item": URL
		} ]
	});

	const _script = head.querySelector(`#${script.id}`) || head.querySelector(`script[type="${script.type}"]`);
	if(_script) return head.replaceChild(script, _script);

	return head.appendChild(script);
};

export const router = createRouter({
	history: createWebHistory(),
	// linkActiveClass: "is-active",
	// linkExactActiveClass: "is-active-exact",
	routes: [ {
		name: "Calendar",
		path: `/:month(${MONTHS.map(({ slug }) => slug).join("|")})`,
		component: defineComponent(Calendar) // defineAsyncComponent(() => import("@/views/Calendar"))
	}, {
		path: "/:_(.*)*",
		redirect: DEFAULT_ROUTE
	} ]
});

let referrer = (document.referrer || location.href) + "";
router.afterEach(({ path: page })=> { // eslint-disable-line no-unused-vars
	/*
	ga("send", { // eslint-disable-line no-undef
		hitType: "pageview",
		page
	});
	*/
	setTimeout(() => {
		console.log("Sending pageview:", page, document.title); // eslint-disable-line no-console

		_paq.push(['setReferrerUrl', referrer + ""]);
		_paq.push(['setCustomUrl', referrer = location.href + ""]);
		_paq.push(['setDocumentTitle', document.title + ""]);

		// remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
		_paq.push(['deleteCustomVariables', 'page']); 
		_paq.push(['trackPageView']);

		// make Matomo aware of newly added content
		_paq.push(['MediaAnalytics::scanForMedia', document.body]);
		_paq.push(['FormAnalytics::scanForForms', document.body]);
		_paq.push(['trackContentImpressionsWithinNode', document.body]);
		_paq.push(['enableLinkTracking']);
	});
});
