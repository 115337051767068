"use strict";

export const isString = (string = "") => !!string && typeof string === "string";

export const capitalize = (string = "") => {
	string = string.toString();
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const normalize = (string = "") => string.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

export const shorten = (string = "", length = 2) => string.toString().slice(0, length | 0);

export const pad = (string = 0, length = 2, character = 0) => {
	length = length | 0;
	return (character.toString().repeat(length) + string.toString()).slice(-length);
};

export const rot13 = (string = "") => string.toString().replace(/[a-zA-Z]/g, c => String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26));

/*
// LZW-compress a string
export const lzwEncode = (s = "") => {
    var dict = {};
    var data = s.toString().split("");
    var out = [];
    var currChar;
    var phrase = data[0];
    var code = 256;
    for (var i=1; i<data.length; i++) {
        currChar=data[i];
        if (dict[phrase + currChar] != null) {
            phrase += currChar;
        }
        else {
            out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
            dict[phrase + currChar] = code;
            code++;
            phrase=currChar;
        }
    }
    out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
    for (var i=0; i<out.length; i++) { // eslint-disable-line no-redeclare
        out[i] = String.fromCharCode(out[i]);
    }
    return out.join("");
};

// Decompress an LZW-encoded string
export const lzwDecode = (s = "") => {
    var dict = {};
    var data = s.toString().split("");
    var currChar = data[0];
    var oldPhrase = currChar;
    var out = [currChar];
    var code = 256;
    var phrase;
    for (var i=1; i<data.length; i++) {
        var currCode = data[i].charCodeAt(0);
        if (currCode < 256) {
            phrase = data[i];
        }
        else {
           phrase = dict[currCode] ? dict[currCode] : (oldPhrase + currChar);
        }
        out.push(phrase);
        currChar = phrase.charAt(0);
        dict[code] = oldPhrase + currChar;
        code++;
        oldPhrase = phrase;
    }
    return out.join("");
};
*/
