"use strict";

import {
	TODAY_DATE,
	formatArray,
	toString,
	getEasterDate,
	getRemainingDayCount,
	getLastFridayOfMonthDate,
	getLastSundayOfMonthDate,
} from "@/utils/Date";
import { pad, normalize, rot13 } from "@/utils/String";

const DateUtils = {
	formatArray,
	toString,
	getEasterDate,
	getRemainingDayCount,
	getLastFridayOfMonthDate,
	getLastSundayOfMonthDate,
};

const StringUtils = { pad, normalize, rot13 };

import $months from "@/data/sk-months-rot13.json";
import $weekdays from "@/data/sk-weekdays-rot13.json";
import $namedays from "@/data/sk-namedays-rot13.json";
import $publicHolidays from "@/data/sk-public-holidays-rot13.json";
import $schoolHolidays from "@/data/sk-school-holidays-rot13.json";
import $memorialDays from "@/data/sk-memorial-days-rot13.json";

const { domain: WWW_DOMAIN } = document;
export const DOMAIN = WWW_DOMAIN.replace(/^www\./, "");
/*
const DESKTOP_WIDTH = 1044; // px
const { matches: isMobile } = matchMedia(`screen and (max-width: ${DESKTOP_WIDTH-1}px)`);
export const IS_MOBILE = isMobile;
*/
export const CURRENT_YEAR = TODAY_DATE.getFullYear();
export const CURRENT_MONTH = TODAY_DATE.getMonth() + 1;
export const CURRENT_DAY = TODAY_DATE.getDate();

export const SCHOOL_YEAR_ARRAY = [
	CURRENT_YEAR - (CURRENT_MONTH >= 9 - 2 ? 0 : 1),
]; // -2 aby to slo vidiet uz cez leto
SCHOOL_YEAR_ARRAY.push(SCHOOL_YEAR_ARRAY[0] + 1);

export const SCHOOL_YEAR_STRING = SCHOOL_YEAR_ARRAY.join("\xa0/\xa0");

export const MONTHS = $months.map((title, index) => ({
	title: StringUtils.rot13(title),
	slug: StringUtils.normalize(StringUtils.rot13(title)),
	index: index + (index > 3 ? -3 : 9),
}));

export const WEEKDAYS = $weekdays.map((title, index) => ({
	title: StringUtils.rot13(title),
	index,
}));

export const NAMEDAYS = $namedays /*.filter(({ s }) => !!s)*/
	.map(({ d: day, m: month, t: title }) => ({
		day,
		month,
		title: StringUtils.rot13(title).replace(/,\s*/g, ", "),
	}));

// ym -> date string
// NAMEDAYS.forEach(nameday => Object.assign(nameday, { date: DateUtils.formatArray([ SCHOOL_YEAR_ARRAY[nameday.month >= 9 ? 0 : 1], nameday.month, nameday.day ]) }));

// 0 = neaktivne, 1 = statny sviatok, 2 = den pracovneho pokoja
export const PUBLIC_HOLIDAYS = $publicHolidays
	.filter(({ s }) => !!s)
	.map(({ d: day, m: month, t: title, n: isNotHoliday, s: status }) => ({
		day,
		month,
		title: StringUtils.rot13(title),
		isNotHoliday,
		status,
	}));

// 0 = neaktivne, 1 = prazdniny, 2 = regionalne prazdniny
export const SCHOOL_HOLIDAYS = $schoolHolidays
	.filter(({ s }) => !!s)
	.map(({ b: startDate, e: endDate, t: title, s: status }) => ({
		startDate,
		endDate,
		title: StringUtils.rot13(title),
		status,
	}));

// 0 = neaktivne, 1 = aktivne
export const MEMORIAL_DAYS = $memorialDays
	.filter(({ s }) => !!s)
	.map(({ d: day, m: month, t: title, s: status }) => ({
		day,
		month,
		title: StringUtils.rot13(title),
		status,
	}));

// doplnenie rokov
// PUBLIC_HOLIDAYS.forEach(event => Object.assign(event, { year: SCHOOL_YEAR_ARRAY[event.month >= 9 ? 0 : 1] }));

// velka noc
const EASTER_DATE = DateUtils.getEasterDate(SCHOOL_YEAR_ARRAY[1]);
PUBLIC_HOLIDAYS.filter(({ month }) => !month).forEach((event) => {
	const date = new Date(EASTER_DATE.getTime());
	date.setDate(date.getDate() + event.day);
	Object.assign(event, { month: date.getMonth() + 1, day: date.getDate() });
});

// ymd -> date string
PUBLIC_HOLIDAYS.forEach((event) =>
	Object.assign(event, {
		date: DateUtils.formatArray([
			SCHOOL_YEAR_ARRAY[event.month >= 9 ? 0 : 1],
			event.month,
			event.day,
		]),
	})
);

// regionalne prazdniny formatting
SCHOOL_HOLIDAYS.filter(({ status }) => status === 2).forEach((event) =>
	Object.assign(event, {
		title: event.title
			.split(":")
			.map((title, index) =>
				!index
					? title + ":"
					: title
							.split(",")
							.map((region) => `- ${region}`)
							.join("\n")
			)
			.join("\n"),
	})
);

// ymd -> date string
MEMORIAL_DAYS.forEach((event) =>
	Object.assign(event, {
		date: DateUtils.formatArray([
			SCHOOL_YEAR_ARRAY[event.month >= 9 ? 0 : 1],
			event.month,
			event.day,
		]),
	})
);

// o kolko dni
PUBLIC_HOLIDAYS.forEach((event) =>
	Object.assign(event, {
		inDays: DateUtils.getRemainingDayCount(new Date(event.date)),
	})
);
SCHOOL_HOLIDAYS.forEach((event) =>
	Object.assign(event, {
		inDays: DateUtils.getRemainingDayCount(new Date(event.startDate)),
	})
);
MEMORIAL_DAYS.forEach((event) =>
	Object.assign(event, {
		inDays: DateUtils.getRemainingDayCount(new Date(event.date)),
	})
);

/* **** **** */

const DST_START_DATE = DateUtils.getLastSundayOfMonthDate(
	SCHOOL_YEAR_ARRAY[0],
	10
);

const DST_END_DATE = DateUtils.getLastSundayOfMonthDate(
	SCHOOL_YEAR_ARRAY[1],
	3
);

const DST_START = DateUtils.toString(DST_START_DATE);

const DST_END = DateUtils.toString(DST_END_DATE);

export const DST = [
	{
		title: "Zmena z letného času na zimný čas (3:00 &rarr; 2:00)",
		date: DST_START,
	},
	{
		title: "Zmena zo zimného času na letný čas (2:00 &rarr; 3:00)",
		date: DST_END,
	},
];

const HELLOWEEN = `${SCHOOL_YEAR_ARRAY[0]}-10-31`;

const BLACK_FRIDAY_DATE = DateUtils.getLastFridayOfMonthDate(
	SCHOOL_YEAR_ARRAY[0],
	11
);

const CYBER_MONDAY_DATE = new Date(BLACK_FRIDAY_DATE.getTime());
CYBER_MONDAY_DATE.setDate(CYBER_MONDAY_DATE.getDate() + 3);

const BLACK_FRIDAY = DateUtils.toString(BLACK_FRIDAY_DATE);

const CYBER_MONDAY = DateUtils.toString(CYBER_MONDAY_DATE);

export const OTHER = [
	{
		title: "Helloween",
		date: HELLOWEEN,
	},
	{
		title: "Black Friday",
		date: BLACK_FRIDAY,
	},
	{
		title: "Cyber Monday",
		date: CYBER_MONDAY,
	},
];

// o kolko dni
DST.forEach((event) =>
	Object.assign(event, {
		inDays: DateUtils.getRemainingDayCount(new Date(event.date)),
	})
);
OTHER.forEach((event) =>
	Object.assign(event, {
		inDays: DateUtils.getRemainingDayCount(new Date(event.date)),
	})
);
