<template>
	<transition name="fade">
		<div v-if="showToast" id="service-worker-toast" class="message is-hidden-print">
			<div class="message-body">
				<p>
					K dispozícii je nový obsah.
				</p>
				<div class="buttons is-right">
					<button type="button" class="button is-small is-ghost" title="Zavrieť" @click.prevent="showToast = false">
						Zavrieť
					</button>
					<button type="button" class="button is-small is-primary" title="Obnoviť" @click.prevent="reload">
						<strong>Obnoviť</strong>
					</button>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	"use strict";

	import { ref, onMounted, onUnmounted } from "vue";
	import { clear } from "@/utils/Cache";

	const CacheUtils = { clear };

	export default {
		name: "ServiceWorkerToast",
		setup() {
			const showToast = ref(false);
			let onSwReloadListener;

			const reload = () => CacheUtils.clear().then(() => location.reload()).catch(console.error); // eslint-disable-line no-console

			onMounted(() => onSwReloadListener = document.addEventListener("sw:update", () => showToast.value = true, { passive: true }));

			onUnmounted(() => onSwReloadListener = document.removeEventListener("sw:update", onSwReloadListener));

			return { showToast, reload };
		}
	};
</script>

<style scoped lang="scss">
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	$margin: 2.5rem;

	.message {
		position: fixed;
		bottom: $margin;
		left: $margin;
		margin-bottom: 0; // kvoli bulme...
		box-shadow: 0 0 .75rem 0 rgba(0, 0, 0, .25);
		z-index: 125;

		.message-body {
			padding: 1.25rem 1.5rem;
			border-color: $primary;

			p {
				margin-bottom: 1rem;
			}
		}
	}

	@media screen and (max-width: $desktop - 1px) {
		.message {
			right: $margin;
		}
	}
</style>
