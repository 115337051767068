<template>
	<div class="floating-buttons is-hidden-print">
		<ul>
			<transition name="fade">
				<li v-if="showScrollButton">
					<button type="button" class="box button is-primary is-rounded has-icon-only" title="Návrat na začiatok stránky" @click.prevent="scrollToTop">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="'svg-inline'" v-bind:class="'svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M5.23 10.64a1 1 0 001.41.13L11 7.14V19a1 1 0 002 0V7.14l4.36 3.63a1 1 0 101.28-1.54l-6-5-.15-.09-.13-.07a1 1 0 00-.72 0l-.13.07-.15.09-6 5a1 1 0 00-.13 1.41z"/></svg>
					</button>
				</li>
			</transition>
			<li>
				<button type="button" class="box button is-primary is-rounded has-icon-only" title="Zdieľať tento kalendár na Twitter" @click.prevent="shareOnTwitter">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-bind:svg-inline="'svg-inline'" v-bind:class="'svg-icon is-brand'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
				</button>
			</li>
			<li>
				<button type="button" class="box button is-primary is-rounded has-icon-only" title="Zdieľať tento kalendár na Facebook" @click.prevent="shareOnFacebook">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" v-bind:svg-inline="'svg-inline'" v-bind:class="'svg-icon is-brand'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
				</button>
			</li>
			<li>
				<button type="button" class="box button is-primary is-rounded has-icon-only" title="Zdieľať tento kalendár e-mailom" @click.prevent="shareByEmail">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="'svg-inline'" v-bind:class="'svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M19 4H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 01-1-1V7.25l7.4 5.55a1 1 0 00.6.2 1 1 0 00.6-.2L20 7.25V17a1 1 0 01-1 1z"/></svg>
				</button>
			</li>
			<li v-if="showPrintButton">
				<button type="button" class="box button is-primary is-rounded has-icon-only" title="Vytlačiť tento kalendár" @click.prevent="printPage">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="'svg-inline'" v-bind:class="'svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M19.36 7H18V5a1.92 1.92 0 00-1.83-2H7.83A1.92 1.92 0 006 5v2H4.64A2.66 2.66 0 002 9.67v6.66A2.66 2.66 0 004.64 19h.86a2 2 0 002 2h9a2 2 0 002-2h.86A2.66 2.66 0 0022 16.33V9.67A2.66 2.66 0 0019.36 7zM8 5h8v2H8zm-.5 14v-4h9v4zM20 16.33a.66.66 0 01-.64.67h-.86v-2a2 2 0 00-2-2h-9a2 2 0 00-2 2v2h-.86a.66.66 0 01-.64-.67V9.67A.66.66 0 014.64 9h14.72a.66.66 0 01.64.67z"/></svg>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
	"use strict";

	/*
			<li v-if="showInstallPwaButton">
				<button type="button" class="button is-primary is-rounded has-icon-only" title="Pridať na domovskú obrazovku" @click.prevent="installPwa">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="'svg-inline'" v-bind:class="'svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M6.09 21.06a1 1 0 01-1-1L4.94 5.4a2.26 2.26 0 012.18-2.35L16.71 3a2.27 2.27 0 012.23 2.31l.14 14.66a1 1 0 01-.49.87 1 1 0 01-1 0l-5.7-3.16-5.29 3.23a1.2 1.2 0 01-.51.15zm5.76-5.55a1.11 1.11 0 01.5.12l4.71 2.61-.12-12.95c0-.2-.13-.34-.21-.33l-9.6.09c-.08 0-.19.13-.19.33l.12 12.9 4.28-2.63a1.06 1.06 0 01.51-.14z"/></svg>
				</button>
			</li>
	 */

	import { ref, onMounted, onUnmounted} from "vue";
	import { DOMAIN, SCHOOL_YEAR_STRING } from "@/data";
	import { scrollToTop, scrollTop, throttle } from "@/utils/Scroll";

	const ScrollUtils = { scrollToTop, scrollTop, throttle };

	export default {
		name: "FloatingButtons",
		setup() {
			const { open, print } = window;
			const { addEventListener, removeEventListener } = document;
			const showScrollButton = ref(false);
			const showPrintButton = "print" in window;
			// const showInstallPwaButton = ref(false);
			let onScrollListener;
			// let onInstallPwaListener;
			// let installPwaPrompt = null;

				const shareOnTwitter = () => {
				open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(location.href)}&text=${encodeURIComponent(document.title)}`, "sharer:twitter", "toolbar=0,status=0");
				ga("send", "event",{ // eslint-disable-line no-undef
					eventCategory: "Web Analytics",
					eventAction: "share-page",
					eventLabel: "twitter",
					nonInteraction: true
				});
			};

			const shareOnFacebook = () => {
				open(`https://www.facebook.com/sharer.php?u=${encodeURIComponent(location.href)}&t=${encodeURIComponent(document.title)}`, "sharer:facebook", "toolbar=0,status=0"); // ,width=626,height=436
				ga("send", "event",{ // eslint-disable-line no-undef
					eventCategory: "Web Analytics",
					eventAction: "share-page",
					eventLabel: "facebook",
					nonInteraction: true
				});
			};

			const shareByEmail = () => {
				open(`mailto:?subject=Školský kalendár&body=Školský kalendár ${SCHOOL_YEAR_STRING.replace(/\s+/g, " ")}: https://www.${DOMAIN}/`);
				ga("send", "event",{ // eslint-disable-line no-undef
					eventCategory: "Web Analytics",
					eventAction: "share-page",
					eventLabel: "e-mail",
					nonInteraction: true
				});
			};

			const printPage = () => print(); // ga() mame v App.vue lebo moze sa tam dostat cez FAB alebo menu alebo ctrl+p

			onMounted(() => onScrollListener = addEventListener("scroll", ScrollUtils.throttle(() => showScrollButton.value = !!ScrollUtils.scrollTop()), { passive: true }));

			onUnmounted(() => onScrollListener = removeEventListener("scroll", onScrollListener));
			/*
			const installPwaHandler = event => {
				if(!("prompt" in event)) return;
				event.preventDefault();
				installPwaPrompt = event;
				showInstallPwaButton.value = true;
			};
			
			const installPwa = () => {
				if(!installPwaPrompt || !showInstallPwaButton.value) return;
				installPwaPrompt.prompt();
				installPwaPrompt.userChoice.then(choiceResult => {
					if(choiceResult.outcome === "accepted") console.log("User accepted the install prompt."); // eslint-disable-line no-console
					else console.log("User dismissed the install prompt."); // eslint-disable-line no-console
					installPwaPrompt = null;
				});
				ga("send", "event", { // eslint-disable-line no-undef
					eventCategory: "Web Analytics",
					eventAction: "install-pwa",
					nonInteraction: true
				});
			};

			onMounted(() => onInstallPwaListener = addEventListener("beforeinstallprompt", installPwaHandler, { passive: true }));

			onUnmounted(() => onInstallPwaListener = removeEventListener("beforeinstallprompt", onInstallPwaListener));
			*/
			return {
				showScrollButton,
				showPrintButton,
				// showInstallPwaButton,
				scrollToTop: ScrollUtils.scrollToTop,
				shareOnTwitter,
				shareOnFacebook,
				shareByEmail,
				printPage // ,
				// installPwa
			};
		}
	};
</script>

<style scoped lang="scss">
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	$margin: 2.5rem;

	.floating-buttons {
		// $margin: 2.5rem;

		position: fixed;
		// bottom: $margin;
		// right: $margin;

		ul > li {
			&:not(:last-child) {
				margin-bottom: $margin / 4;
			}
			/* kvoli tomu, ze print je hidden na mobile, ale element v dome ostava
			&:not(:first-child) {
				margin-top: $margin / 4;
			}
			*/
		}

		.button {
			@include square($margin);
			z-index: 95;

			.svg-icon.is-brand {
				@include square(1.25rem);
				padding: .125rem;
			}
		}
	}

	@media screen and (max-width: $desktop - 1px) {
		.floating-buttons {
			bottom: $margin / 2;
			right: $margin / 2;
		}
	}

	@media screen and (min-width: $desktop) {
		.floating-buttons {
			bottom: $margin;
			right: $margin;
		}
	}
</style>
