"use strict";

// https://composition-api.vuejs.org/api.html
// https://v3.vuejs.org/guide/introduction.htm
// https://v3.vuejs.org/guide/migration/introduction.html
// https://next.router.vuejs.org/

import { createApp } from "vue";
import { router } from "@/router";
import { register } from "@/utils/ServiceWorker";

import App from "@/App.vue";

import "@/matomo";
import "@/ga";
// import "@/webVitals";
// import "@fontsource/lato";
import "@/styles/app.scss";

const ServiceWorkerUtils = { register };

const app = createApp(App);
app.use(router);
app.mount("#app");

if(PRODUCTION_BUILD) ServiceWorkerUtils.register();
