<template>
	<section class="section holidays-section">
		<div class="container">
			<div class="columns is-desktop">
				<div class="column">
					<app-holidays-table title="Štátne sviatky a dni pracovného pokoja" :holidays="publicHolidays" className="is-public-holiday" :showDays="filters.days" :break-after="true" />
					<app-holidays-table title="Školské prázdniny" :holidays="schoolHolidays" className="is-school-holiday" :showDays="filters.days" />
					<app-holidays-table title="Školské prázdniny - regionálne" :holidays="schoolRegionalHolidays" className="is-school-holiday-regional" :showDays="filters.days" :break-after="true" />
					<app-holidays-table title="Pamätné dni" :holidays="memorialDays" :showDays="filters.days" :break-after="true" />
					<app-holidays-table title="Zmena času" :holidays="dst" :showDays="filters.days" />
					<app-holidays-table title="Ďalšie užitočné dni" :holidays="other" :showDays="filters.days" />
				</div>
				<div class="column is-narrow">
					<form class="form is-hidden-touch is-hidden-print has-text-right" @submit.prevent>
						<div class="field">
							<input type="checkbox" id="show-in-days" class="switch is-rounded" v-model="filters.days" />
							<label for="show-in-days" title="Zobraziť počet zostávajúcich dní">Zobraziť počet zostávajúcich dní</label>
						</div>
					</form>
					<div class="banner is-hidden-touch is-hidden-print has-text-centered">
						<!--
						<div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-size-1 has-text-weight-light" style="width:300px;height:600px;background-color:#ededed;color:#4a4a4a;">
							300&times;600
						</div>
						<p class="is-size-7 has-background-primary has-text-light">
							Priestor pre Vašu reklamu - <a rel="noopener" target="_blank" href="https://www.findl.sk/" title="Kontakt">kontakt</a>
						</p>
						-->
						<a rel="noopener" target="_blank" :href="`https://www.zmazanedomeny.sk/${banner.link}`" :title="banner.title">
							<img class="box is-paddingless is-inline-block" :src="`https://www.zmazanedomeny.sk/static/images/ws/${banner.image.desktop}`" :alt="banner.title" loading="lazy" width="300" height="600" />
						</a>
					</div>
					<div class="banner is-hidden-desktop is-hidden-print has-text-centered">
						<!--
						<div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-size-1 has-text-weight-light" style="width:300px;height:250px;background-color:#ededed;color:#4a4a4a;margin:auto;">
							300&times;250
						</div>
						<p class="is-size-7 has-background-primary has-text-light" style="width:300px;margin:auto;">
							Priestor pre Vašu reklamu - <a rel="noopener" target="_blank" href="https://www.findl.sk/" title="Kontakt">kontakt</a>
						</p>
						-->
						<a rel="noopener" target="_blank" :href="`https://www.zmazanedomeny.sk/${banner.link}`" :title="banner.title">
							<img class="box is-paddingless is-inline-block" :src="`https://www.zmazanedomeny.sk/static/images/ws/${banner.image.mobile}`" :alt="banner.title" loading="lazy" width="300" height="250" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	"use strict";

	//TODO: transition holidays checkmark

	import { ref, watch, defineComponent } from "vue";
	import { useRoute } from "vue-router";
	import HolidaysTable from "@/components/HolidaysTable";
	import { PUBLIC_HOLIDAYS, SCHOOL_HOLIDAYS, MEMORIAL_DAYS, DST, OTHER } from "@/data";
	import { storage } from "@/storage";
	import { random } from "@/utils/Array";

const STORE_COOKIE_CONSENT_KEY = "cookie-consent:is-hidden";
	const STORE_FILTERS_KEY = "holidays:filters:form";

	const BANNERS = [
		{
			link: "domeny",
			title: "Overenie a registrácia domén od 3,95 € | Websupport.sk",
			image: {
				mobile: "WS-Domain-300-250-SK.jpg",
				desktop: "WS-Domain-300-600-SK.jpg"
			}
		}, {
			link: "webhosting",
			title: "Webhosting pre každého | Websupport.sk",
			image: {
				mobile: "WS-Hosting-300-250-SK.jpg",
				desktop: "WS-Hosting-300-600-SK.jpg"
			}
		}, {
			link: "servery",
			title: "Prémiový virtuálny server s vysokou dostupnosťou | Websupport.sk",
			image: {
				mobile: "WS-VPS-300-250-SK.jpg",
				desktop: "WS-VPS-300-600-SK.jpg"
			}
		}, {
			link: "free-web",
			title: "Freeweb - získajte zadarmo webhosting | Websupport.sk",
			image: {
				mobile: "FREEWEB_WEBUJ-S-LEHKOSTI-300-250-SK.png",
				desktop: "FREEWEB_WEBUJ-S-LEHKOSTI-300-600-SK.png"
			}
		}
	];

	const DEFAULT_FILTERS = {
		days: false
	};

	export default {
		name: "Holidays",
		components: {
			"app-holidays-table": defineComponent(HolidaysTable)
		},
		setup() {
			const route = useRoute();
			const banner = ref(random(BANNERS));

			const filters = ref(Object.assign({}, DEFAULT_FILTERS, JSON.parse(storage.get(STORE_FILTERS_KEY))));

			const sortByDate = (a, b) => (a.date > b.date) - (a.date < b.date);

			// const publicHolidays = PUBLIC_HOLIDAYS.sort((a, b) => a.year - b.year);
			const publicHolidays = PUBLIC_HOLIDAYS.sort(sortByDate);

			const schoolHolidays = SCHOOL_HOLIDAYS.filter(({ status }) => status !== 2);

			const schoolRegionalHolidays = SCHOOL_HOLIDAYS.filter(({ status }) => status === 2).map(holiday => Object.assign({}, holiday, { title: holiday.title.split("\n").map((title, index) => !index ? title.replace(/\s+/g, "\xa0") : `<small>${title}</small>`).join("<br />") }));

			const memorialDays = MEMORIAL_DAYS.sort(sortByDate);

			// const trackAffiliate = (destination = "") => ((new Image()).src = `/affiliate/track?destination=${encodeURIComponent(destination)}`);

			watch(filters, () => storage.get(STORE_COOKIE_CONSENT_KEY) && storage.set(STORE_FILTERS_KEY, JSON.stringify(filters.value)), { deep: true });
			watch(() => route.params.month, () => banner.value = random(BANNERS));

			return {
				filters,
				publicHolidays,
				schoolHolidays,
				schoolRegionalHolidays,
				memorialDays,
				dst: DST,
				other: OTHER,
				banner
			};
		}
	};
</script>

<style scoped lang="scss">
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	$margin: 3rem;

	.columns > .column:last-child {
		margin-left: $margin;

		.banner {
			&.is-hidden-desktop {
				margin: $margin * -.375 auto $margin;
			}

			&.is-hidden-touch {
				position: sticky;
				top: $margin;
				margin-top: $margin / 2;
			}

			p {
/*
				&:not(:last-child) {
					margin-bottom: 1rem;
				}
*/

				&.has-background-primary {
					$padding: .5rem;
					padding: $padding;
					// border-bottom-left-radius: $padding;
					// border-bottom-right-radius: $padding;

					a {
						color: $white-ter; // = light
						font-weight: bold;
					}
				}
			}
		}
	}

	@media screen and (max-width: $desktop - 1px) {
		.columns {
			display: flex;
			flex-direction: column-reverse;

			& > .column:last-child {
				margin: 0;
			}
		}
	}
</style>
