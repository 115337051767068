<template>
	<header class="header hero is-primary is-hidden-print">
		<div class="hero-head">
			<nav role="navigation" class="navbar">
				<div class="container">
					<div class="navbar-brand">
						<router-link class="navbar-item is-active is-uppercase" :to="defaultRoute" :title="`Školský kalendár ${schoolYearString}`" @click.prevent>
							Školský kalendár {{ schoolYearString }}
						</router-link>
						<div class="navbar-burger burger" :class="{ 'is-active': showMenu }" :title="`${showMenu ? 'Zatvoriť' : 'Otvoriť'} menu`" @click.prevent="showMenu = !showMenu">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					<div class="navbar-menu" :class="{ 'is-active': showMenu }">
						<!-- <div class="navbar-start"></div> -->
						<div class="navbar-end">
							<div class="navbar-item has-dropdown is-hoverable">
								<a class="navbar-link is-arrowless is-relative" href="" title="Stiahnúť školský kalendár vo formáte PDF" @click.prevent>
									Stiahnúť PDF
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="'svg-inline'" v-bind:class="'svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M12 15.5a1 1 0 01-.71-.29l-4-4a1 1 0 111.42-1.42L12 13.1l3.3-3.18a1 1 0 111.38 1.44l-4 3.86a1 1 0 01-.68.28z"/></svg>
								</a>
								<div class="navbar-dropdown is-right">
									<a v-for="{ title, slug, index } of months" :key="index" class="navbar-item dropdown-item" target="_blank" :href="`https://www.skolskykalendar.sk/pdf/${slug}.pdf`" :title="`Školský kalendár vo formáte PDF pre mesiac ${title.toLowerCase()}`" @click="trackDownload(slug)">
										{{ title }}
									</a>
									<hr class="dropdown-divider is-hidden-touch" />
									<a class="navbar-item dropdown-item" target="_blank" href="https://www.skolskykalendar.sk/pdf/statne-sviatky-a-dni-pracovneho-pokoja.pdf" title="Štátne sviatky a dni pracovného pokoja vo formáte PDF" @click="trackDownload('statne-sviatky-a-dni-pracovneho-pokoja')">
										Štátne sviatky <br />a dni pracovného pokoja
									</a>
									<a class="navbar-item dropdown-item" target="_blank" href="https://www.skolskykalendar.sk/pdf/skolske-prazdniny.pdf" title="Školské prázdniny vo formáte PDF" @click="trackDownload('skolske-prazdniny')">
										Školské prázdniny
									</a>
									<a class="navbar-item dropdown-item" target="_blank" href="https://www.skolskykalendar.sk/pdf/pamatne-dni.pdf" title="Pamätné dni vo formáte PDF" @click="trackDownload('pamatne-dni')">
										Pamätné dni
									</a>
									<a class="navbar-item dropdown-item" target="_blank" href="https://www.skolskykalendar.sk/pdf/zmena-casu-a-dalsie-uzitocne-datumy.pdf" title="Zmena času a ďalšie užitočné dni vo formáte PDF" @click="trackDownload('skolske-prazdniny')">
										Zmena času <br />a ďalšie užitočné dni
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
		<div class="hero-body">
			<div class="container has-text-centered">
				<div class="columns">
					<div class="column">
						<h1 class="title is-spaced is-size-2 has-text-weight-light">
							Školské prázdniny {{ schoolYearString }}
						</h1>
						<h2 class="subtitle">
							Dnes je {{ [ currentDay, currentMonth, currentYear ].join(". ") }}<template v-if="nameday">, meniny má {{ nameday }}</template>
						</h2>
					</div>
					<div v-if="showInstallPwaButton" class="column is-one-third">
						<div class="box">
							<h3 class="is-size-4 has-text-white has-text-weight-light mb-4">
								Nainštaluj si aplikáciu<br />
								do svojho zariadenia
							</h3>
							<button type="button" class="button is-white is-outlined is-medium is-fullwidth" @click.prevent="installPwa">
								Nainštalovať aplikáciu
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hero-foot">
			<div class="container is-relative">
				<nav ref="tabsRef" class="tabs is-boxed is-centered is-marginless">
					<ul>
						<li v-for="{ title, slug, index } of months" :key="index" :class="{ 'is-active': slug === route.params.month }">
							<router-link class="is-borderless" :to="{ name: 'Calendar', params: { month: slug } }" :title="`${title} ${schoolYearArray[index < 9 ? 1 : 0]}`">{{ title }}</router-link>
						</li>
					</ul>
				</nav>
				<p class="tabs-help has-text-grey has-text-centered is-hidden-desktop">
					Mesiace sa dajú posúvať ťahom prsta do strán
				</p>
			</div>
		</div>
	</header>
</template>

<script>
	"use strict";

/*
	<div class="navbar-item">
		<button type="button" class="button is-primary is-rounded has-icon-only has-tooltip-arrow has-tooltip-hidden-touch has-tooltip-left has-tooltip-light has-tooltip-fade" :class="{ 'has-tooltip-active': showTooltip }" data-tooltip="Vytlačte si tento kalendár" title="Vytlačiť tento kalendár" @click.prevent="print" @mouseleave.prevent="showTooltip = false">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="'svg-inline'" v-bind:class="'svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M19.36 7H18V5a1.92 1.92 0 00-1.83-2H7.83A1.92 1.92 0 006 5v2H4.64A2.66 2.66 0 002 9.67v6.66A2.66 2.66 0 004.64 19h.86a2 2 0 002 2h9a2 2 0 002-2h.86A2.66 2.66 0 0022 16.33V9.67A2.66 2.66 0 0019.36 7zM8 5h8v2H8zm-.5 14v-4h9v4zM20 16.33a.66.66 0 01-.64.67h-.86v-2a2 2 0 00-2-2h-9a2 2 0 00-2 2v2h-.86a.66.66 0 01-.64-.67V9.67A.66.66 0 014.64 9h14.72a.66.66 0 01.64.67z"/></svg>
			<span class="is-hidden-desktop">Vytlačiť tento kalendár</span>
		</button>
	</div>
*/

	import { ref, watch, onMounted, onUnmounted } from "vue";
	import { useRouter, useRoute } from "vue-router";
	import { CURRENT_YEAR, CURRENT_MONTH, CURRENT_DAY, SCHOOL_YEAR_ARRAY, SCHOOL_YEAR_STRING, MONTHS, NAMEDAYS } from "@/data";
	import { DEFAULT_ROUTE } from "@/router";
	// import { storage } from "@/storage";

	// const STORE_TOOLTIP_KEY = "navbar:tooltip:is-hidden";

	export default {
		name: "Hero",
		setup() {
			const { addEventListener, removeEventListener } = document;
			const router = useRouter();
			const route = useRoute();
			const showMenu = ref(false);
			// const showTooltip = ref(!storage.get(STORE_TOOLTIP_KEY));
			const showInstallPwaButton = ref(false);
			const tabsRef = ref(null);
			let onKeyUpListener;
			let onInstallPwaListener;
			let installPwaPrompt = null;

			const { title: nameday } = NAMEDAYS.find(({ month, day }) => month === CURRENT_MONTH && day === CURRENT_DAY);

			const trackDownload = (title = "") => !!title && ga("send", "event", { // eslint-disable-line no-undef
				eventCategory: "Web Analytics",
				eventAction: "download-pdf",
				eventLabel: title,
				nonInteraction: true
			});
/*
			const print = () => {
				window.print();
				ga("send", "event", { // eslint-disable-line no-undef
					eventCategory: "Web Analytics",
					eventAction: "print-page",
					nonInteraction: true
				});
			};
*/
			const installPwaHandler = event => {
				if(!("prompt" in event)) return;
				event.preventDefault();
				installPwaPrompt = event;
				showInstallPwaButton.value = true;
			};
			
			const installPwa = () => {
				if(!installPwaPrompt || !showInstallPwaButton.value) return;
				installPwaPrompt.prompt();
				installPwaPrompt.userChoice.then(choiceResult => {
					if(choiceResult.outcome === "accepted") console.log("User accepted the install prompt."); // eslint-disable-line no-console
					else console.log("User dismissed the install prompt."); // eslint-disable-line no-console
					installPwaPrompt = null;
				});
				ga("send", "event", { // eslint-disable-line no-undef
					eventCategory: "Web Analytics",
					eventAction: "install-pwa",
					nonInteraction: true
				});
			};

			onMounted(() => onInstallPwaListener = addEventListener("beforeinstallprompt", installPwaHandler, { passive: true }));

			onUnmounted(() => onInstallPwaListener = removeEventListener("beforeinstallprompt", onInstallPwaListener));

			const onKeyUp = (event = null) => {
				if(!event) return;

				const { code: KEY_CODE } = event;
				if(!["ArrowLeft", "ArrowRight"].includes(KEY_CODE)) return;

				const { params: { month: SLUG } } = route;
				const SLUGS = MONTHS.map(({ slug }) => slug);
				const SLUG_INDEX = SLUGS.indexOf(SLUG);
				if(SLUG_INDEX === -1) return;

				switch(KEY_CODE) {
					case "ArrowLeft": {
						if(SLUG_INDEX) router.push({ name: "Calendar", params: { month: SLUGS[SLUG_INDEX - 1] }});
						break;
					}
					case "ArrowRight": {
						if(SLUG_INDEX !== SLUGS.length - 1) router.push({ name: "Calendar", params: { month: SLUGS[SLUG_INDEX + 1] }});
						break;
					}
				}
			};

			// watch(showTooltip, () => storage.set(STORE_TOOLTIP_KEY, !showTooltip.value));

			const stop = watch(() => route.params.month, () => {
				const { params: { month: SLUG } } = route;
				const SLUGS = MONTHS.map(({ slug }) => slug);
				const SLUG_INDEX = SLUGS.indexOf(SLUG);
				if(SLUG_INDEX === -1) return;

				// const activeTab = tabsRef.value.querySelector(".is-active");
				const activeTab = tabsRef.value.querySelector(`li:nth-child(${SLUG_INDEX})`);
				if(activeTab && activeTab.offsetLeft) tabsRef.value.scrollLeft = activeTab.offsetLeft;
				stop();
			});

			onMounted(() => onKeyUpListener = addEventListener("keyup", onKeyUp, { passive: true }));

			onUnmounted(() => onKeyUpListener = removeEventListener("keyup", onKeyUpListener));

			return {
				route,
				showMenu,
				// showTooltip,
				showInstallPwaButton,
				tabsRef,
				defaultRoute: DEFAULT_ROUTE,
				months: MONTHS,
				currentYear: CURRENT_YEAR,
				currentMonth: CURRENT_MONTH,
				currentDay: CURRENT_DAY,
				schoolYearArray: SCHOOL_YEAR_ARRAY,
				schoolYearString: SCHOOL_YEAR_STRING,
				nameday,
				trackDownload,
				// print,
				// share,
				installPwa
			};
		}
	};
</script>

<style scoped lang="scss">
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	// bulma override
	.hero.is-primary {
		.hero-head {
			.navbar-item,
			.navbar-link {
				&:hover,
				&:active,
				&:focus,
				&:focus-within,
				&.is-active {
					text-decoration: none;
				}
			}
		}

		.hero-body .box {
			transition-property: transform;
			transition-duration: $transition-duration;
			transition-timing-function: $transition-timing-function;

			&:hover {
				transform: scale(1.1);
			}
		}

		.hero-foot .tabs {
			// ul > li {
				a {
					&:hover,
					&:active,
					&:focus,
					&:focus-within {
						text-decoration: none;
					}
				}

				// firefox fix
				.is-active {
					color: $primary !important;
				}
			// }
		}
	}

	@media screen and (max-width: $desktop - 1px) {
		.hero {
			/*
			.hero-head {
				.button .svg-icon {
					margin-right: .5rem;
				}
			}
			*/
			.hero-foot .tabs + .tabs-help {
				position: absolute;
				bottom: -1.5rem;
				left: 0;
				width: 100%;
				font-size: .875rem;
			}
		}
	}

	/* dropodown doplnene styly */

	@media screen and (min-width: $desktop) {
		.hero {
			.hero-head {
				// override, lebo tam nieco davalo padding-right: 3rem;
				.navbar-dropdown .navbar-item {
					padding: .375rem 1rem;
				}
				/*
				.navbar-end .navbar-item {
					&:not(:first-of-type) {
						padding-left: 0;
					}
					&:not(:last-of-type) {
						padding-right: 0;
					}
				}
				*/
			}

			.hero-body .columns > .column:first-of-type {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}

	//TODO: rozhodit do MQ vyssie
	// drowpdown arrow svg icon
	.hero .hero-head .has-dropdown {
		.is-arrowless {
			padding-right: 1.5rem;

			.svg-icon {
				position: absolute;
				top: .5rem;
				right: .5rem;

				@media screen and (min-width: $desktop) {
					top: .75rem;
					right: .75rem;
				}
			}

			@media screen and (min-width: $desktop) {
				padding-right: 2.25rem;
			}
		}
	}
</style>
