"use strict";

import { isArray } from "@/utils/Array";
import { isString, pad } from "@/utils/String";

const ArrayUtils = { isArray };
const StringUtils = { isString, pad };

export const TODAY_DATE = new Date;

const MS_PER_DAY = 24 * 60 * 60 * 1e3;

const isDate = (date = null) => !!date && date instanceof Date;

export const formatArray = (dateArray = []) => {
	if(!ArrayUtils.isArray(dateArray) || dateArray.length !== 3 || dateArray.some(item => !item)) return;
	return dateArray.map((n, i) => StringUtils.pad(n | 0, !i ? 4 : 2, 0)).join("-");
};

export const formatString = (dateString = "") => {
	if(!StringUtils.isString(dateString)) return;
	return dateString.split("-").map(v => v | 0).reverse().join(".\xa0");
};

export const toString = (date = null) => {
	if(!isDate(date)) return;
	return formatArray([ date.getFullYear(), date.getMonth() + 1, date.getDate() ]); 
};

export const getDayCountWord = (dayCount = 0) => {
	if(!dayCount || dayCount <= 0) return;
	if(dayCount === 1) return "deň";
	if(dayCount < 5) return "dni";
	return "dní";
};

/* eslint-disable no-irregular-whitespace */
const getEaster = (year = 0) => {
	year = year | 0;
	var f = Math.floor,
		// Golden Number - 1
		G = year % 19,
		C = f(year / 100),
		// related to Epact
		H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
		// number of days from 21 March to the Paschal full moon
		I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
		// weekday for the Paschal full moon
		J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
		// number of days from 21 March to the Sunday on or before the Paschal full moon
		L = I - J,
		month = 3 + f((L + 40) / 44),
		day = L + 28 - 31 * f(month / 4);
	return { year, month, day };
};
/* eslint-enable no-irregular-whitespace */

export const getEasterDate = (year = 0) => {
	const { year: EASTER_YEAR, month: EASTER_MONTH, day: EASTER_DAY } = getEaster(year);
	return new Date(EASTER_YEAR, EASTER_MONTH - 1, EASTER_DAY);
};

/*
The ISO 8601 definition for week 01 is the week with the first Thursday of the Gregorian year (i.e. of January) in it.
If 1 January is on a Monday, Tuesday, Wednesday or Thursday, it is in W01. If it is on a Friday, it is part of W53 of the previous year. If it is on a Saturday, it is part of the last week of the previous year which is numbered W52 in a common year and W53 in a leap year. If it is on a Sunday, it is part of W52 of the previous year.
*/
export const getWeekNumber = (date = null) => {
	if(!isDate(date)) return;
	// Copy date so don't modify original
	date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	// Set to nearest Thursday: current date - current day number + 4
	date.setDate(date.getDate() - (date.getDay() || 7) + 4);
	// Get first day of year
	const yearStart = new Date(date.getFullYear(), 0);
	// Calculate full weeks to nearest Thursday
	const weekNumber = Math.ceil((((date - yearStart) / MS_PER_DAY) + 1) / 7);
	// Return week number
	return weekNumber;
};

export const getRemainingDayCount = (date = null) => {
	if(!isDate(date)) return;
	return Math.ceil((date - TODAY_DATE) / MS_PER_DAY);
};

export const getFirstDayOfMonthDate = (year = 0, month = 0) => new Date((year | 0), (month | 0) - 1);

export const getLastDayOfMonthDate = (year = 0, month = 0) => new Date((year | 0), (month | 0), 0);

const getLastDayOfPreviousMonthDate = (year = 0, month = 0) => new Date((year | 0), (month | 0) - 1, 0);

export const getMonthDayCount = (year = 0, month = 0) => getLastDayOfMonthDate(year, month).getDate();

export const getLastMonthDayCount = (year = 0, month = 0) => getLastDayOfPreviousMonthDate(year, month).getDate();

export const getLastSundayOfMonthDate = (year = 0, month = 0) => {
	const date = getLastDayOfMonthDate(year, month);
	date.setDate(date.getDate() - date.getDay());
	return date;
};

export const getLastFridayOfMonthDate = (year = 0, month = 0) => {
	const date = getLastDayOfMonthDate(year, month);
	const day = date.getDay();
	date.setDate(date.getDate() - day - 2 + (day >= 5 ? 7 : 0));
	return date;
};
