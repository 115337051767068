<template>
	<transition name="fade">
		<section v-if="showCookieConsent" class="section cookie-consent-section has-background-dark has-text-light is-hidden-print">
			<div class="container">
				<div class="columns">
					<div class="column">
						<p class="is-size-7">
							Táto stránka využíva cookies a podobné technológie ukladania dát za účelom zachovania preferencií užívateľa. Možnosť zmazať tieto dáta sa nachádza v pätičke tejto stránky.
						</p>
					</div>
					<div class="column is-narrow buttons has-text-right">
						<button type="button" class="button is-primary" title="Súhlasím s využívaním týchto technológií" @click.prevent="showCookieConsent = false">
							Súhlasím
						</button>
						<button type="button" class="button is-primary" title="Nesúhlasím s využívaním týchto technológií" @click.prevent="showCookieConsent = null">
							Nesúhlasím
						</button>
					</div>
				</div>
			</div>
		</section>
	</transition>
</template>

<script>
	"use strict";

	/*
	<br />
							<a rel="noopener noreferrer" target="_blank" href="https://policies.google.com/technologies/cookies?hl=sk" title="Ako používa spoločnosť google súbory cookie">Viac informácií o cookies</a>
	*/

	/*
	<p class="is-size-7">
			Táto stránka využíva cookies a podobné technológie ukladania dát. Používaním tejto stránky udeľujete súhlas s využívaním týchto technológií. V opačnom prípade prosím opustite túto stránku alebo použite <a rel="noopener noreferrer" target="_blank" href="https://tools.google.com/dlpage/gaoptout" title="Google Analytics Opt-out Browser Add-on">toto</a> rozšírenie pre prehliadače. Možnosť zmazať tieto dáta sa nachádza v pätičke tejto stránky.
			<br />
			<a rel="noopener noreferrer" target="_blank" href="https://policies.google.com/technologies/cookies?hl=sk" title="Ako používa spoločnosť google súbory cookie">Viac informácií o cookies</a>
		</p>
	 */

	import { ref, watch } from "vue";
	import { storage } from "@/storage";

	const STORE_COOKIE_CONSENT_KEY = "cookie-consent:is-hidden";

	export default {
		name: "CookieConsent",
		setup() {
			const showCookieConsent = ref(!storage.get(STORE_COOKIE_CONSENT_KEY));

			watch(showCookieConsent, () => {
				// storage.set(STORE_COOKIE_CONSENT_KEY, !showCookieConsent.value);
				if(showCookieConsent.value === false) {
					localStorage.setItem(STORE_COOKIE_CONSENT_KEY, !showCookieConsent.value);
					// location.reload();
				}
				ga("send", "event", { // eslint-disable-line no-undef
					eventCategory: "Web Analytics",
					eventAction: "cookie-consent",
					nonInteraction: true
				});
			});

			return { showCookieConsent };
		}
	};
</script>

<style scoped lang="scss">
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	.cookie-consent-section {
		position: fixed; bottom: 0;
		width: 100%;
		padding: 1.5rem .75rem; // half of .section
		z-index: 100;

		a {
			color: $white-ter;
			font-weight: bold;
		}
	}
</style>
